import Cookies from "js-cookie";
import { Constants } from "../../Modules/Authentication/utils";
import store from "../../Redux/Store";
import { GetDateFromEpochPropTypes } from "./types";
import { ServiceFunctionLibraryType } from "../Api/types";
import {
  getCountry,
  getCurrency,
  getData,
  getFeatureDoc,
  getLeType,
  getNinType,
  getTimezones,
  getTinType,
} from "../Api/Services";
import jwtDecode from "jwt-decode";
import axios from "axios";

export const performLogout = () => {
  Cookies.remove(Constants.ACCESS_TOKEN);
  Cookies.remove(Constants.REFRESH_TOKEN);
  window.location.reload();
};

export const validateToken = (token: string): boolean => {
  try {
    const decoded: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp && decoded.exp < currentTime) {
      // Token is expired
      return false;
    }
    return true;
  } catch (error) {
    console.error("Invalid token", error);
    return false;
  }
};

export const refreshAccessToken = async (
  url: string
): Promise<string | null> => {
  const AccessToken = Cookies.get(Constants.ACCESS_TOKEN);
  const RefreshToken = Cookies.get(Constants.REFRESH_TOKEN);
  if (!RefreshToken) {
    return null;
  }

  try {
    const response = await axios.post(
      url,
      { A: "A", DeviceType: "BA" },
      {
        headers: {
          RequestType: "Token",
          Authorization: `Bearer ${AccessToken}`,
          RefreshToken: RefreshToken,
          DeviceID: getDeviceId(),
        },
      }
    );

    const { access_token, refresh_token } = response.data;
    if (access_token && refresh_token) {
      Cookies.set(Constants.ACCESS_TOKEN, access_token);
      Cookies.set(Constants.REFRESH_TOKEN, refresh_token);
      return access_token;
    }
  } catch (error) {
    console.error("Failed to refresh access token", error);
    return null;
  }
  return null;
};

export const getTenantID = () => {
  return store.getState().AuthReducer?.tenantCode;
};

export const isEmpty = (val: any) => {
  if (val === undefined || val === null) return true;
  if (Array.isArray(val) && val.length === 0) return true;
  if (typeof val === "object" && Object.keys(val).length === 0) return true;
  if (typeof val === "string" && val.trim() === "") return true;
  return false;
};

export const getDateFromEpoch = (props: GetDateFromEpochPropTypes) => {
  const { epoch = "", includeTime = false, ignoreDay = false } = props;
  let date = new Date(+epoch);
  let formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: ignoreDay ? undefined : "numeric",
    year: "numeric",
  });
  if (includeTime)
    formattedDate = date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  if (ignoreDay) {
  }
  return formattedDate;
};

export const getUserId = () => {
  return store.getState().AuthReducer.userId;
};

export const getDeviceId = () => {
  let deviceId = localStorage.getItem("DEVICE_ID");
  if (!deviceId) {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const randomString =
      Math.random().toString(20).substring(2, 14) +
      Math.random().toString(20).substring(2, 14);

    deviceId = `${userAgent}-${platform}-${randomString}`;
  }
  return deviceId;
};

export const serviceFunctionLibrary = (
  props: ServiceFunctionLibraryType
): Promise<unknown> => {
  const serviceFunctionsMap = {
    getNinType,
    getTinType,
    getTimezones,
    getCurrency,
    getCountry,
    getData,
    getLeType,
    getFeatureDoc,
  } as const;

  const { functionName, params } = props;
  const serviceFunction: Function = serviceFunctionsMap[functionName];
  return serviceFunction(params);
};

export const isValidDate = (d: Date | undefined | null) => {
  if (typeof d === "number") d = new Date(d); // if epoch
  return d instanceof Date && !isNaN(d as any);
};

export const getSUAndTUByTransactionType = (
  transactionType: string
): { subjectUser: number | null; transactionUser: number | null } => {
  const userId = parseInt(getUserId());
  switch (transactionType) {
    case "UE":
      return { subjectUser: null, transactionUser: userId };
    case "UU":
      return { subjectUser: userId, transactionUser: userId };
    case "MO":
      return { subjectUser: userId, transactionUser: userId };
    case "RO":
      return { subjectUser: userId, transactionUser: userId };
    case "SO":
      return { subjectUser: userId, transactionUser: null };
    case "RM":
      return { subjectUser: null, transactionUser: userId };
    case "MM":
      return { subjectUser: null, transactionUser: userId };
    case "SM":
      return { subjectUser: null, transactionUser: null };
    default:
      return { subjectUser: null, transactionUser: null };
  }
};
