import React from "react";
import Dropdown_1 from "./Dropdown_1";
import withFunction from "./withFunction";
import { DropdownPropTypes } from "./types";

const reactMemo: <T>(
  c: T,
  propsAreEqual: (prev: any, curr: any) => boolean
) => T = React.memo;

export const Dropdown = reactMemo(
  Dropdown_1,
  (prevProps, currProps): boolean => {
    const fields = Object.keys(prevProps) as (keyof DropdownPropTypes)[];
    for (let i = 0; i < fields.length; i++) {
      if (prevProps[fields[i]] !== currProps[fields[i]]) {
        console.log(
          "Rerendered based on: " +
            fields[i] +
            " " +
            (currProps.placeholder || currProps.helperText)
        );
        return false; // meaning, the component should rerender;
      }
    }
    return true;
  }
);

export const FunctionDropdown = reactMemo(
  withFunction(Dropdown),
  (prevProps, currProps) => {
    const { params: prevParams }: { params: any } = prevProps;
    const { params: currParams }: { params: any } = currProps;
    
    const prevValue = JSON.stringify(prevParams);
    const currValue = JSON.stringify(currParams);
    
    if (prevValue !== currValue) {
      console.log("Rerendered based on: " + prevValue + " " + currValue);
      return false;
    }
    return true;
  }
);

export default Dropdown;
